import { IInvoice } from '../interfaces';

const getCustomerFieldKeys = (customer: IInvoice['customer']) => {
    const customerModifiedObject: Partial<IInvoice['customer']> = { ...customer };

    if (customer.email === '' || customer.email === undefined) {
        delete customerModifiedObject.email;
    } else if (customer.phone_number === '' || customer.phone_number === undefined) {
        delete customerModifiedObject.phone_number;
    }
    return Object.keys(customerModifiedObject);
};

export default getCustomerFieldKeys;
