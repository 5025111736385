import { ReactElement } from 'react';
import InvoiceSuccess from './InvoiceSuccess';
import InvoiceFailed from './InvoiceFailed';

type Status = {
    status?: string;
};

const InvoiceStatus = ({ status }: Status): ReactElement => {
    return <>{status === 'success' ? <InvoiceSuccess /> : <InvoiceFailed />}</>;
};

export default InvoiceStatus;
