import * as React from 'react';
import { colors } from '../../assets/colors';

type Props = {
  color?: string;
};

const Line: React.FC<Props> = ({ color }) => (
  <div
    style={{
      width: '100%',
      height: '1px',
      backgroundColor: color || colors.grey,
      margin: '25px 0',
    }}
  />
);

export default Line;
