import axios from 'axios';
import { clearScreenDown } from 'readline';

type UserInfo = {
  [key: string]: string | undefined;
};
type id = string | undefined;

enum Field {
  city = 'City',
  customer_email = 'Customer_email',
  name = 'Name',
  postal_code = 'Postal code',
  street_address = 'Sreet address',
}

const bankIdSigning = (args: UserInfo, invoiceId: id): void => {
  const mandatoryFields: (keyof typeof Field)[] = [
    'city',
    'customer_email',
    'name',
    'postal_code',
    'street_address',
  ];
  mandatoryFields.forEach((field: keyof typeof Field) => {
    if (args[field] === '') {
      return;
    }
  });

  axios
    .post(
      process.env.REACT_APP_API_BASE_URL +
        `web/invoice/${invoiceId}/sign_invoice/`,
      args
    )
    .then(res => (window.location.href = res.data.url))
    .catch((error: Error): void => {
      console.log(error.message);
    });
};

export default bankIdSigning;
