// src/App.js
import lottie from 'lottie-web';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import pinkInifityAnim from '../../assets/animations/loader.json';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    padding-top: 100;
    z-index: 5;
`;

const AnimatedLoader = () => {
    const load = useCallback(() => {
        lottie.loadAnimation({
            container: document.querySelector('#react-logo')!,
            animationData: pinkInifityAnim,
            renderer: 'svg', // "canvas", "html"
            loop: true, // boolean
            autoplay: true // boolean
        });
    }, []);

    useEffect(() => {
        load();
    }, []);

    return (
        <Wrapper>
            <div id="react-logo" />
        </Wrapper>
    );
};

export default AnimatedLoader;
