import { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { RowView, Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';

const Wrapper = styled.div``;

const due_dates = [
    {
        name: '7 Dagar  förfallotid'
    },
    {
        name: '30 Dagar  förfallotid'
    },
    {
        name: '60 Dagar  förfallotid'
    },
    {
        name: '90 Dagar  förfallotid'
    },
    {
        name: '120 Dagar  förfallotid'
    }
];

const Dot = styled.div<DueDatesProps>`
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 1vw;
    border-width: ${(props) => (props.selected ? '6px' : '1px')};
    border-style: solid;
    border-color: ${(props) => (props.selected ? colors.main : colors.grey)};
    border-radius: 100%;
`;

interface DueDatesProps {
    selected: boolean;
}

const DueDate = styled.div<DueDatesProps>`
    background: ${colors.faded};
    padding: 24px;
    border-radius: 6px;
    margin-top: 2vh;
    opacity: ${(props) => (props.selected ? 1 : 0.7)};
    border-width: 0.5;
    border-color: ${(props) => (props.selected ? colors.main : colors.white)};
    border-style: solid;
    box-sizing: content-box;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

const DueDates = () => {
    const [selectedDueDate, setselectedDueDate] = useState(0);

    return (
        <Wrapper>
            <RoundBox>
                <Text i={'Betalsätt'} fontWeight="bold" header />
                {due_dates.map((item, index) => {
                    return (
                        <DueDate
                            selected={selectedDueDate === index}
                            key={index}
                            onClick={() => setselectedDueDate(index)}>
                            <RowView>
                                <RowView>
                                    <Dot selected={selectedDueDate === index} />
                                    <Text fontWeight="bold" i={item.name} color={colors.dark2} />
                                </RowView>
                            </RowView>
                        </DueDate>
                    );
                })}
            </RoundBox>
        </Wrapper>
    );
};

export default DueDates;
