const customerKeyToDisplayName = (key: string | null) => {
    let parsed_key = '';

    if (key === 'email') parsed_key = 'E-post';
    else if (key === 'name') parsed_key = 'Namn';
    else if (key === 'phone_number') parsed_key = 'Telefonnummer';
    else if (key === 'city') parsed_key = 'Stad';
    else if (key === 'care_of') parsed_key = 'C/o-address';
    else if (key === 'street_address') parsed_key = 'Gatuadress';
    else if (key === 'postal_code') parsed_key = 'Postnummer';
    else {
        return '';
    }

    return parsed_key;
};

export default customerKeyToDisplayName;
