import { createRef, RefObject } from 'react';
import { colors } from '../../assets/colors';
import { IInvoice } from '../../interfaces';
import { Text, RowView } from '../../helpers';
import { useGlobalState, setGlobalState } from '../../state';
import AnimatedLoader from '../AnimatedLoader/AnimatedLoader';
import CustomForm from '../CustomForm/CustomForm';
import InvoiceDetails from '../InvoiceDetails/InvoiceDetails';
import RoundBox from '../RoundBox/RoundBox';
import { BankIDLogo } from '../Logos';
import bankIdSigning from '../../api/bankIdSigning/bankIdSigning';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const UserInfo = () => {
    const [invoice] = useGlobalState('invoice');
    const [customer] = useGlobalState('customer');
    const [loading] = useGlobalState('loading');

    const arr = [
        {
            name: 'email',
            placeholder: 'Vart skall fakturan skickas?'
        },
        {
            name: 'name',
            placeholder: 'Please enter your name'
        },
        {
            name: 'street_address',
            placeholder: 'Please your street address'
        },
        {
            name: 'care_of',
            placeholder: 'Please enter your c/o'
        },
        {
            name: 'postal_code',
            placeholder: 'Please enter your postal code'
        },
        {
            name: 'city',
            placeholder: 'Please enter your city'
        }
    ];

    const { invoice_id } = useParams();

    const FetchUserInfo = () => {
        type FormikInitialValuesType = {
            name: string;
            email: string;
            street_address: string;
            care_of: string;
            postal_code: string;
            city: string;
        };

        const UserInfoSchema = Yup.object({
            name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
            street_address: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
            care_of: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
            postal_code: Yup.string()
                .min(6, 'Postal code must be 6 digits number')
                .required('Required!'),
            city: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required!')
        });

        const formFields = [
            {
                name: 'email',
                placeholder: 'Vart skall fakturan skickas?'
            },
            {
                name: 'name',
                placeholder: 'Please enter your name'
            },
            {
                name: 'street_address',
                placeholder: 'Please your street address'
            },
            {
                name: 'care_of',
                placeholder: 'Please enter your c/o'
            },
            {
                name: 'postal_code',
                placeholder: 'Please enter your postal code'
            },
            {
                name: 'city',
                placeholder: 'Please enter your city'
            }
        ];

        const initialValues: Partial<IInvoice['customer']> = {
            email: invoice.customer.email,
            name: invoice.customer.name,
            street_address: invoice.customer.street_address,
            care_of: invoice.customer.care_of,
            postal_code: invoice.customer.postal_code,
            city: invoice.customer.city
        };

        const onSubmit = (values: FormikInitialValuesType) => {
            setGlobalState('loading', true);
            bankIdSigning(values, invoice_id);
            setGlobalState('loading', false);
        };

        return (
            <>
                <RoundBox>
                    <CustomForm
                        initialValues={initialValues}
                        formTitle="test"
                        validationSchema={UserInfoSchema}
                        onFormSubmit={onSubmit}
                        fields={formFields}
                    />

                    {/* <Line /> */}
                    <RowView style={{ justifyContent: 'center' }}>
                        {/* <Text
              i="Betallösningen hanteras av"
              color={colors.dark}
            />
            <img
              alt="resurs"
              style={{ width: '70px', marginLeft: '5px' }}
              src="https://tukuz.com/wp-content/uploads/2020/10/resurs-bank-logo-vector.png"
            /> */}
                    </RowView>
                </RoundBox>
                {loading && <AnimatedLoader />}
            </>
        );
    };

    return (
        <>
            <InvoiceDetails />
        </>
    );
};

export default UserInfo;
