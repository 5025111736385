import Error from '../Error/Error';

const NotFound = () => {
  return (
    <>
      <Error message="This page not found" />
    </>
  );
};

export default NotFound;
