import * as React from 'react';
import { CSSProperties } from 'styled-components';

type Props = {
    children?: React.ReactNode
    flexStart?: boolean
    spaceBetween?: boolean
    flexEnd?: boolean
    spaceEvenly?: boolean
    style?: CSSProperties
}

const RowView = ({ children, flexStart, spaceBetween, flexEnd, spaceEvenly, style }: Props) =>
    <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent:
            flexStart ? "flex-start" :
                spaceBetween ? "space-between" :
                    flexEnd ? "flex-end" :
                        spaceEvenly ? "space-evenly" :
                            "space-between",
        ...style
    }}>
        {children}
    </div>


export default RowView