import { useParams, useLocation } from 'react-router-dom';
import { useGetInvoice } from '../../api';
import {
    AnimatedLoader,
    CustomerDetails,
    Error,
    UserInfo,
    PaymentMethods,
    DueDates
} from '../../components';
import { Clamp } from '../../helpers';
import { useGlobalState } from '../../state';
import InvoiceStatus from '../../components/InvoiceStatus/InvoiseStatus';
import MobileAppsButtons from '../../components/MobileAppsButtons/MobileAppsButtons';

const Checkout = () => {
    const { invoice_id } = useParams();
    const { fetchedInvoice } = useGetInvoice(invoice_id);
    const [invoice] = useGlobalState('invoice');
    const location = useLocation();

    // Renders components after backend redirection
    if (location.state?.failed === 'redirected') {
        return (
            <>
                {invoice.is_signed ? (
                    <>
                        {invoice ? (
                            <Clamp>
                                <CustomerDetails />
                                {/* <PaymentMethods /> */}
                                {/* <BankIDButton /> */}
                                <InvoiceStatus status="success" />
                                {/* <UserInfo /> */}
                            </Clamp>
                        ) : (
                            <AnimatedLoader />
                        )}
                    </>
                ) : (
                    <Clamp>
                        <CustomerDetails />
                        {/* <PaymentMethods /> */}
                        {/* <BankIDButton /> */}
                        <InvoiceStatus status="failed" />
                        <UserInfo />
                        {/* <InvoiceDetails /> */}
                    </Clamp>
                )}
            </>
        );
    }

    // Render Error component in case of no invoice
    if (fetchedInvoice && invoice.is_signed === null) {
        return (
            <>
                <Error message="Invoice not found" />
            </>
        );
    }

    // Render components depends on "is_signed" field of invoice.

    switch (invoice.is_signed) {
        case true:
            return (
                <>
                    {invoice ? (
                        <Clamp>
                            <InvoiceStatus status="success" />
                            <CustomerDetails />
                            {/* <PaymentMethods /> */}
                            {/* <BankIDButton /> */}
                            {/* <UserInfo /> */}
                            <MobileAppsButtons />
                        </Clamp>
                    ) : (
                        <AnimatedLoader />
                    )}
                </>
            );
        case false:
            return (
                <>
                    {invoice ? (
                        <Clamp>
                            <CustomerDetails />
                            {/* <PaymentMethods /> */}
                            {/* <DueDates /> */}
                            {/* <BankIDButton /> */}
                            {/* <InvoiceStatus status="failed" /> */}
                            <UserInfo />
                        </Clamp>
                    ) : (
                        <AnimatedLoader />
                    )}
                </>
            );
        default:
            <Error message="Something went worng" />;
    }

    return <></>;
};

export default Checkout;
