type LogoType = {
    width?: string;
    height?: string;
};

const BankIDLogo = ({ width = '34', height = '32' }: LogoType) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 34 32">
            <title>bankid</title>
            <path
                fill="#fff"
                d="M10.679 16.713l1.414-8.907h-1.511c-0.388 0.012-0.772-0.089-1.106-0.288-0.333-0.199-0.603-0.488-0.779-0.836-0.085-0.248-0.289-1.093 0.878-1.918 0.337-0.193 0.599-0.495 0.742-0.857 0.030-0.116 0.027-0.238-0.006-0.353-0.034-0.115-0.098-0.219-0.187-0.3-0.407-0.293-0.904-0.429-1.402-0.386-0.391-0.040-0.786 0.040-1.128 0.232-0.344 0.192-0.619 0.485-0.789 0.839-0.004 0.155 0.033 0.308 0.109 0.444 0.075 0.135 0.185 0.248 0.319 0.326 0.155 0.097 0.29 0.224 0.396 0.373s0.18 0.319 0.219 0.497c0.039 0.179 0.041 0.364 0.007 0.544-0.033 0.18-0.103 0.351-0.204 0.504-0.265 0.373-0.619 0.677-1.029 0.882-0.409 0.205-0.863 0.307-1.321 0.295h-1.551c-0.123 0.866-2.226 14.169-2.388 15.214h8.364c0.076-0.471 0.46-2.988 0.99-6.308l-0.036 0.001z"></path>
            <path
                fill="#fff"
                d="M21.879 0h-8.516l-1.132 7.207h1.446c0.359 0.014 0.717-0.059 1.042-0.215 0.325-0.154 0.607-0.386 0.821-0.674 0.094-0.136 0.147-0.295 0.154-0.46-0.011-0.137-0.052-0.269-0.123-0.386-0.070-0.117-0.168-0.216-0.284-0.288-0.199-0.124-0.364-0.296-0.481-0.5-0.119-0.204-0.184-0.433-0.192-0.667-0.002-0.058 0.001-0.115 0.011-0.172 0.186-0.503 0.532-0.93 0.985-1.217 0.453-0.286 0.987-0.415 1.521-0.367 0.651-0.051 1.297 0.145 1.81 0.546 0.162 0.152 0.281 0.344 0.345 0.556s0.070 0.439 0.019 0.654c-0.178 0.505-0.521 0.935-0.974 1.221-0.825 0.578-0.718 1.082-0.665 1.236 0.135 0.232 0.329 0.423 0.563 0.553 0.233 0.132 0.498 0.197 0.766 0.19h2.196v0.011c2.997 0.021 4.604 1.402 4.101 4.616-0.471 2.988-2.763 4.274-5.495 4.294l-1.082 6.898h1.596c3.206 0.107 6.338-0.973 8.798-3.034 2.458-2.062 4.069-4.959 4.521-8.134 1.339-8.484-4.037-11.867-11.75-11.867z"></path>
            <path
                fill="#fff"
                d="M0.91 26.088h3.419c1.457 0 1.81 0.743 1.703 1.414-0.057 0.284-0.19 0.548-0.384 0.763-0.194 0.215-0.443 0.372-0.719 0.457 0.169 0.036 0.329 0.103 0.472 0.2 0.143 0.097 0.265 0.22 0.36 0.365s0.16 0.306 0.192 0.476c0.032 0.169 0.030 0.344-0.006 0.512-0.083 0.48-0.337 0.913-0.715 1.217-0.379 0.306-0.856 0.463-1.342 0.443h-3.891l0.91-5.849zM3.17 28.51c0.239 0.032 0.482-0.030 0.677-0.174 0.194-0.143 0.324-0.358 0.363-0.596 0.064-0.45-0.14-0.761-0.804-0.761h-0.59l-0.235 1.531h0.589zM2.807 31.036c0.685 0 1.082-0.279 1.178-0.846 0.075-0.494-0.205-0.781-0.866-0.781h-0.677l-0.257 1.638h0.619l0.004-0.011z"></path>
            <path
                fill="#fff"
                d="M10.732 31.979c-0.89 0.065-1.318-0.032-1.531-0.418-0.467 0.289-1.005 0.441-1.554 0.44-1.007 0-1.361-0.525-1.265-1.103 0.058-0.303 0.22-0.576 0.46-0.77 0.557-0.482 1.928-0.546 2.463-0.91 0.044-0.408-0.123-0.557-0.619-0.557-0.709 0.039-1.387 0.311-1.928 0.77l0.205-1.329c0.644-0.498 1.434-0.768 2.248-0.77 1.039 0 1.96 0.428 1.788 1.563l-0.204 1.286c-0.076 0.45-0.053 0.589 0.45 0.6l-0.514 1.199zM9.189 29.965c-0.471 0.3-1.349 0.248-1.446 0.866-0.014 0.062-0.013 0.127 0.002 0.19 0.015 0.062 0.045 0.12 0.087 0.168 0.041 0.050 0.094 0.088 0.153 0.113 0.058 0.025 0.122 0.037 0.186 0.033 0.324-0.015 0.635-0.123 0.9-0.311-0.018-0.14-0.011-0.282 0.021-0.418l0.097-0.641z"></path>
            <path
                fill="#fff"
                d="M12.391 27.406h1.778l-0.097 0.589c0.411-0.416 0.968-0.655 1.553-0.665 0.996 0 1.458 0.612 1.297 1.608l-0.46 2.988h-1.778l0.386-2.473c0.075-0.45-0.065-0.664-0.408-0.664-0.159 0.011-0.314 0.060-0.45 0.145-0.135 0.084-0.249 0.2-0.331 0.338l-0.408 2.645h-1.778l0.696-4.511z"></path>
            <path
                fill="#fff"
                d="M18.304 26.088h1.777l-0.449 2.872 1.703-1.554h2.195l-2.184 1.928 1.756 2.598h-2.239l-1.35-2.088h-0.017l-0.322 2.088h-1.78l0.91-5.844z"></path>
            <path fill="#fff" d="M24.601 26.088h2.046l-0.9 5.837h-2.046l0.9-5.837z"></path>
            <path
                fill="#fff"
                d="M27.642 26.088h2.924c0.392-0.045 0.791 0.004 1.16 0.143 0.369 0.14 0.699 0.366 0.964 0.659 0.264 0.294 0.455 0.646 0.555 1.029 0.1 0.382 0.107 0.782 0.021 1.167-0.098 0.787-0.481 1.511-1.077 2.035s-1.364 0.809-2.157 0.804h-3.3l0.91-5.837zM29.538 30.533c0.215 0.020 0.43-0.005 0.634-0.073 0.204-0.068 0.392-0.177 0.552-0.32 0.159-0.145 0.288-0.319 0.377-0.516 0.089-0.196 0.136-0.407 0.14-0.622 0.124-0.77-0.123-1.531-1.221-1.531h-0.546l-0.472 3.063h0.536z"></path>
        </svg>
    );
};

export default BankIDLogo;
