import { IInvoice } from '../interfaces';

// Function to get set fields and placeholder depends on what fields are field in invoice

const getFormFields = (customer: Partial<IInvoice['customer']>) => {
    const { email, phone_number } = customer;

    if (!email && !phone_number) {
        return [
            {
                name: 'email',
                placeholder: 'Ange din e-postadress'
            },
            {
                name: 'phone_number',
                placeholder: 'Enter your number'
            }
        ];
    }

    if (!email && phone_number) {
        return [
            {
                name: 'email',
                placeholder: 'Ange din e-postadress'
            }
        ];
    }

    if (email && !phone_number) {
        return [
            {
                name: 'phone_number',
                placeholder: 'Enter your number'
            }
        ];
    }

    return [];
};

export default getFormFields;
