import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useGlobalState } from '../../state';
import { Clamp } from '../../helpers';
import { CustomerDetails } from '../../components';
import { useGetInvoice } from '../../api';
import InvoiceStatus from '../../components/InvoiceStatus/InvoiseStatus';
import { AnimatedLoader, UserInfo } from '../../components';
import { useEffect } from 'react';

const CheckoutFailed = () => {
  const { invoice_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/invoice/${invoice_id}/`, {
      replace: true,
      state: {
        failed: 'redirected',
      },
    });
  });

  return <></>;
};

export default CheckoutFailed;

//   {
//     invoice ? (
//       <Clamp>
//         <CustomerDetails />
//         {/* <PaymentMethods /> */}
//         {/* <BankIDButton /> */}
//         <InvoiceStatus status="failed" />
//         <UserInfo />
//       </Clamp>
//     ) : (
//       <AnimatedLoader />
//     );
//   }
