import { useState } from 'react';
import { useGlobalState, setGlobalState } from '../../state';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { RowView, Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';
import { BankIDLogo, VisaMasterLogo } from '../Logos';
import CheckBox from '../Icons/CheckBox/CheckBox';

const Wrapper = styled.div``;

const payment_types = [
    {
        name: 'Faktura',
        method: 'invoice'
    },
    {
        name: 'Delbetalning. Mer info',
        method: 'PartialPayment'
    },
    {
        name: 'Direkt bankbetalning',
        method: 'CompanyInvoice'
    },
    {
        name: 'Betala direkt med kort ',
        method: 'CardPayment'
    }
];

const DotContainer = styled.div<PaymentMethodProps>`
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 10px;
    background-color: transparent;
    border: ${(props) => (props.selected ? `1px solid ${colors.main}` : 'none')};
    margin-right: 5px;
`;

const Dot = styled.div<PaymentMethodProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
    background: ${(props) => (props.selected ? colors.main : colors.grey)};
`;

const Icons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 22px;
    justify-content: flex-end;
    flex-direction: column;
    gap: 5px;

    @media screen and (min-width: 460px) {
        margin-top: 0;
    }

    @media screen and (min-width: 800px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
`;
type CheckBoxProps = {
    width?: string;
    height?: string;
    className?: string;
};

const CheckBoxIcon = styled(CheckBox)<CheckBoxProps>`
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 22px;
`;

interface PaymentMethodProps {
    selected: boolean;
}

const PaymentMethod = styled.div<PaymentMethodProps>`
    position: relative;
    height: ${(props) => (props.selected ? '90px' : '')};
    background: ${(props) =>
        props.selected ? 'linear-gradient(90deg, #ED1E79 0%, #9E005D 100%)' : colors.faded};
    padding: 13px;
    border-radius: ${(props) => (props.selected ? '10px' : '6px')};
    margin-top: 2vh;
    opacity: ${(props) => (props.selected ? 1 : 0.7)};
    border-width: 0.5;
    border-color: ${(props) => (props.selected ? colors.main : colors.white)};
    border-style: solid;
    box-sizing: content-box;
    color: ${(props) => (props.selected ? colors.white : '')};
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

const PaymentMethods = () => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    const [invoice] = useGlobalState('invoice');

    const onSelect = (index: number) => {
        setSelectedPaymentMethod(index);

        const updatedPaymentMethod = payment_types[index].method;
        setGlobalState('invoice', { ...invoice, payment_method: updatedPaymentMethod });
    };

    return (
        <Wrapper>
            <RoundBox>
                <Text i={'Betalsätt'} fontWeight="bold" header />
                {payment_types.map((item, index) => {
                    return (
                        <PaymentMethod
                            selected={selectedPaymentMethod === index}
                            key={index}
                            onClick={() => onSelect(index)}>
                            <RowView style={{ height: '100%' }}>
                                <RowView spaceBetween>
                                    {/* <DotContainer selected={selectedPaymentMethod === index}>
                                        <Dot selected={selectedPaymentMethod === index} />
                                    </DotContainer> */}
                                    <Text
                                        fontWeight="normal"
                                        i={item.name}
                                        color="inherit"
                                        style={{ marginLeft: '5px' }}
                                        large
                                    />
                                </RowView>
                                {index === 0 ? (
                                    <>
                                        {selectedPaymentMethod === index ? (
                                            <>
                                                <CheckBoxIcon width="50" height="50" />
                                                <img
                                                    alt="dicopay"
                                                    style={{
                                                        // width: '70px'
                                                        height: '30px'
                                                    }}
                                                    src={require('../../assets/images/dicopay.png')}
                                                />
                                            </>
                                        ) : (
                                            <img
                                                alt="dicopay"
                                                style={{
                                                    // width: '70px'
                                                    height: '30px'
                                                }}
                                                src={require('../../assets/images/dicopay_logo2.jpeg')}
                                            />
                                        )}
                                    </>
                                ) : index === 1 ? (
                                    <>
                                        {selectedPaymentMethod === index ? (
                                            <>
                                                <CheckBoxIcon width="50" height="50" />
                                                <img
                                                    alt="dicopay"
                                                    style={{
                                                        // width: '70px'
                                                        height: '30px'
                                                    }}
                                                    src={require('../../assets/images/dicopay.png')}
                                                />
                                            </>
                                        ) : (
                                            <img
                                                alt="dicopay"
                                                style={{
                                                    // width: '70px'
                                                    height: '30px'
                                                }}
                                                src={require('../../assets/images/dicopay_logo2.jpeg')}
                                            />
                                        )}
                                    </>
                                ) : index === 2 ? (
                                    <>
                                        {selectedPaymentMethod === index ? (
                                            <>
                                                <CheckBoxIcon width="50" height="50" />
                                                <BankIDLogo width="60" height="60" />
                                            </>
                                        ) : (
                                            <img
                                                alt="bankId logo"
                                                src={require('../../assets/images/logo-bank-id.png')}
                                                height="30px"></img>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {selectedPaymentMethod === 3 ? (
                                            <>
                                                <CheckBoxIcon width="50" height="50" />
                                                <VisaMasterLogo width="100" height="30" />
                                            </>
                                        ) : (
                                            <VisaMasterLogo width="70" height="18" />
                                        )}
                                    </>
                                )}
                            </RowView>
                        </PaymentMethod>
                    );
                })}
            </RoundBox>
        </Wrapper>
    );
};

export default PaymentMethods;
