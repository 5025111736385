import * as Yup from 'yup';

const UserInfoSchema = Yup.object({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    street_address: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    care_of: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    postal_code: Yup.string()
        .length(5, 'Postal code must be 5 digits number')
        .required('Required!'),
    city: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required!')
});

export default UserInfoSchema;
