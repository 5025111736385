import { MutableRefObject, useEffect, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import styled from 'styled-components';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { colors } from '../../assets/colors';
import { customerKeyToDisplayName } from '../../functions';
import { Text, Label } from '../../helpers';
import { IInvoice } from '../../interfaces';
import { setGlobalState, useGlobalState } from '../../state';

type FormFieldProps = {
    name: string;
    customHeader?: string;
    placeholder?: string;
};

const FormField = ({
    name,
    customHeader,
    // displayError,
    placeholder
}: FormFieldProps) => {
    const [invoice] = useGlobalState('invoice');
    const customer: IInvoice['customer'] = invoice['customer'];

    const { setFieldValue, values } = useFormikContext();

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(e.target.name, e.target.value);
        const invoiceTemp = invoice;
        invoiceTemp['customer'][name as keyof typeof customer] = e.target.value;
    };

    const setLabelText = (name: string) => {
        let label;
        if (name === 'phone_number' || name === 'care_of') {
            label = customerKeyToDisplayName(name) + ' ';
            return label;
        } else {
            label = customerKeyToDisplayName(name) + ' *';
            return label;
        }
    };

    return (
        <div style={{ marginBottom: '2vh' }}>
            <Label fontWeight="600" i={setLabelText(name)} name={name} />
            <Input
                placeholder={placeholder ? placeholder : customerKeyToDisplayName(name)}
                name={name}
                onChange={onInputChange}
            />
            <ErrorMessage name={name}>
                {(msg) => (
                    <ErrorWrapper>
                        <IconWrapper>
                            <ErrorIcon sx={{ color: `${colors.error}` }} fontSize="medium" />
                        </IconWrapper>
                        <Text
                            i={msg}
                            fontWeight="bold"
                            color={colors.error}
                            style={{
                                lineHeight: '24px'
                            }}
                        />
                    </ErrorWrapper>
                )}
            </ErrorMessage>
        </div>
    );
};

const Input = styled(Field)`
    width: 100%;
    border: 1px solid #ecebea;
    border-radius: 5px;
    transition: border-color 0.2s ease, padding-left 0.2s ease;
    padding: 20px 20px;
    font-size: 16px;
    height: 24px;
    margin-top: 1vh;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ErrorWrapper = styled.div`
    display: flex;
    margin-top: 3px;
    align-items: center;
    gap: 3px;
`;

export default FormField;
