import React, { ReactElement } from 'react';

type CheckBoxProps = {
    width?: string;
    height?: string;
    className?: string;
};

const CheckBox = ({ width = '30', height = '30', className = '' }: CheckBoxProps): ReactElement => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 15C1 22.732 7.26801 29 15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15Z"
                fill="url(#paint0_linear_3682_4566)"
            />
            <path
                d="M1.04922 15.0002C1.04922 22.7046 7.29485 28.9502 14.9992 28.9502C22.7036 28.9502 28.9492 22.7046 28.9492 15.0002C28.9492 7.29582 22.7036 1.0502 14.9992 1.0502C7.29485 1.0502 1.04922 7.29582 1.04922 15.0002Z"
                stroke="white"
                strokeWidth="2"
            />
            <path
                d="M11.4854 14.7796C11.4145 14.7087 11.3183 14.6688 11.218 14.6688C11.1177 14.6688 11.0215 14.7087 10.9506 14.7796C10.8797 14.8505 10.8398 14.9467 10.8398 15.047C10.8398 15.1473 10.8797 15.2435 10.9506 15.3144L13.5987 17.9632C13.6338 17.9983 13.6755 18.0262 13.7214 18.0452C13.7673 18.0642 13.8164 18.074 13.8661 18.074C13.9158 18.074 13.965 18.0642 14.0108 18.0452C14.0567 18.0262 14.0984 17.9983 14.1335 17.9632L19.051 13.045C19.1219 12.9741 19.1618 12.8779 19.1618 12.7776C19.1618 12.6773 19.1219 12.5811 19.051 12.5102C18.9801 12.4393 18.8839 12.3994 18.7836 12.3994C18.6833 12.3994 18.5871 12.4393 18.5162 12.5102L13.8661 17.1596L11.4854 14.7796Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3682_4566"
                    x1="4.892"
                    y1="21.496"
                    x2="25.696"
                    y2="7.608"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#ED1E79" />
                    <stop offset="1" stopColor="#9E005D" stopOpacity="0.98" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default CheckBox;
