import styled from 'styled-components';
import { decimalToKr } from '../../functions';
import { IInvoice } from '../../interfaces';
import { useGlobalState } from '../../state';
import Dot from '../Dot/Dot';
import { Line, RowView, Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';

const Wrapper = styled.div`
    margin-top: 8vh;
`;

const EndCustomer = styled(Text)`
    margin-bottom: 2vh;
`;

const CustomerDetails = () => {
    const [invoice] = useGlobalState('invoice');

    const organization = invoice.organization;
    const lines = invoice.lines;
    type DateTraformType = Date | null;
    const dateTranform = (date: DateTraformType) => {
        if (date === null) {
            return '';
        } else {
            const newDate = new Date(date);
            const result = newDate.toUTCString();
            const i = result.indexOf(' ');
            return result.slice(i, result.length - 12);
        }
    };
    return (
        <Wrapper>
            <RoundBox>
                <EndCustomer i={organization.name} fontWeight="bold" header />
                <RowView style={{ justifyContent: 'flex-start', marginTop: '2vh' }}>
                    <Dot />
                    <Text i={`Fakturaunderlag #${invoice.invoice_number}`} />
                </RowView>
                <RowView style={{ marginTop: '2vh' }}>
                    <Text i="Förfallodatum" />
                    <Text i={`${dateTranform(invoice.due_date)}`} />
                </RowView>
                {lines.map((item, index) => (
                    <div key={index}>
                        <Line />
                        <RowView style={{ gap: '10px' }}>
                            <Text i={item.description} />
                            <Text
                                i={decimalToKr(item.amount_excl_vat)}
                                style={{ textWrap: 'nowrap' }}
                            />
                        </RowView>
                    </div>
                ))}
                <Line />
                <RowView>
                    <Text i={`Moms ${invoice.vat_rate}%`} />
                    <Text i={decimalToKr(invoice.vat)} style={{ textWrap: 'nowrap' }} />
                </RowView>
                <RowView style={{ marginTop: '6vh' }}>
                    <Text i="Att betala" fontWeight="bold" />
                    <Text
                        i={decimalToKr(invoice.total_amount)}
                        fontWeight="bold"
                        style={{ textWrap: 'nowrap' }}
                    />
                </RowView>
            </RoundBox>
        </Wrapper>
    );
};

export default CustomerDetails;
