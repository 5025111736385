export const colors = {
    dark: '#222525',
    dark2: '#495050',
    lessdark: 'rgba(120,132,158,.65098)',
    faded: 'rgba(0,0,0,.04)',
    grey: 'rgba(120,132,158,.3)',
    blue: '#008fd3',
    white: '#fff',
    error: '#840600',
    green: '#399f4c',
    red: '#ff0000',
    main: '#EB3789',
    darkGrey: '#454F63',
    whiteSmoke: '#F7F7FA',
    lightGrey: '#959DAD'
};
