import styled from 'styled-components';

import { Text } from '../../helpers';

const PrivacyPolicy = () => {
    const Wrapper = styled.div`
        height: 83dvh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    `;

    return (
        <Wrapper>
            <Text
                i="Här presenteras Prioritet finans policy dokument"
                fontWeight="bold"
                large
                style={{ textAlign: 'center' }}
            />
        </Wrapper>
    );
};

export default PrivacyPolicy;
