import { ReactNode } from 'react';

type Props = {
    amount?: number;
    children: ReactNode;
};

const Clamp = ({ amount, children }: Props) => (
    <div style={{ paddingInline: `${amount || 5}%` }}>{children}</div>
);

export default Clamp;
