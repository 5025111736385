import { CSSProperties } from "styled-components";
import { colors } from "../../assets/colors";


type Props = {
    className?: string;
    i: string | number;
    fontWeight?: string;
    color?: string
    small?: boolean
    large?: boolean
    header?: boolean,
    style?: CSSProperties
}

const Text = ({ className, i, fontWeight, color, small, large, header, style }: Props) => {
    return (
        <p className={className} style={{
            color: color || colors.dark,
            fontWeight: fontWeight || "",
            fontSize: small ? "smaller" : large ? "larger" : header ? "x-large" : "initial",
            ...style
        }}>{i}</p>
    )
}

export default Text