import { Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';

type ErrorProps = {
    message?: string;
    object?: string;
};

const Error = ({ message, object }: ErrorProps) => {
    if (object === 'invoice') {
        return (
            <RoundBox style={{ marginTop: '60px' }}>
                <Text i="Invoice not found" header fontWeight="bold" />
                {message && <Text i={message} style={{ marginTop: '5%' }} />}
            </RoundBox>
        );
    }
    return (
        <RoundBox style={{ marginTop: '60px', marginBottom: '20%' }}>
            <Text i="404" header fontWeight="bold" />
            {message && <Text i={message} style={{ marginTop: '5%' }} />}
        </RoundBox>
    );
};

export default Error;
