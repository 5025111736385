import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';
import { colors } from '../../assets/colors';

const IconTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 35px;
`;

const InvoiceSuccess = () => {
    return (
        <RoundBox
            style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                alignItems: 'flex-start',
                flexDirection: 'column',
                padding: '24px',
                marginTop: '5vh',
                marginBottom: '-4.5vh'
            }}>
            <IconTextWrapper>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon fontSize="large" style={{ fill: 'green' }} />
                </span>
                <Text
                    i="Klart"
                    fontWeight="600"
                    color={colors.dark}
                    style={{
                        textAlign: 'center',
                        fontSize: '24px',
                        lineHeight: '35px'
                    }}
                />
            </IconTextWrapper>
            <Text i="Fakturan undertecknades framgångsrikt" color={colors.lightGrey} />
        </RoundBox>
    );
};

export default InvoiceSuccess;
