import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkout from './screens/CheckoutMain/CheckoutMain';

import SharedLayout from './components/SharedLayout/SharedLayout';
import NotFoundPage from './components/NotFound/NotFoundPage';
import CheckoutFailed from './screens/CheckoutFailed/CheckoutFailed';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

const App: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<SharedLayout />}>
                <Route index element={<NotFoundPage />} />
                <Route path="/invoice/:invoice_id" element={<Checkout />} />
                <Route path="/invoice/:invoice_id/failed" element={<CheckoutFailed />} />
                <Route path="privacy_policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    </BrowserRouter>
);

export default App;
