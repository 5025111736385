import styled from "styled-components"


const Dot = styled.div`
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 9999px;
    margin-right: 10px;
    background: #008fd3;
`
export default Dot