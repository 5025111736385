import { IInvoice } from '../interfaces';
const getInitialValues = (
    customer: Partial<IInvoice['customer']>
): Partial<IInvoice['customer']> => {
    const { email, phone_number } = customer;

    if (email && phone_number) {
        return {
            email,
            phone_number
        };
    }

    if (email && !phone_number) {
        return {
            email
        };
    }

    if (!email && phone_number) {
        return {
            phone_number
        };
    }

    return {
        email: '',
        phone_number: ''
    };
};

export default getInitialValues;
