import { CSSProperties } from 'styled-components';
import { colors } from '../../assets/colors';

type Props = {
    className?: string;
    i: string | number;
    fontWeight?: string;
    color?: string;
    name: string;
    style?: CSSProperties;
};

const Label = ({ className, i, fontWeight, color, name, style }: Props) => {
    return (
        <label
            htmlFor={name}
            className={className}
            style={{
                color: color || colors.dark,
                fontWeight: fontWeight || '',
                ...style
            }}>
            {i}
        </label>
    );
};

export default Label;
