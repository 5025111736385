import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
   *{
       margin: 0;
       padding: 0;
       outline:0;
       box-sizing:border-box;
       font-family: 'Gibson', sans-serif; 
   }

   body {
    font-family: Gibson, sans-serif;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    max-width: 800px;
    margin: 0 auto;
    background-color: rgba(0,0,0,.04);
    font-size: 16px;
    padding: 60px 16px;
   }

   @font-face {
    font-family: 'Gibson';
    src: url('../assets/fonts/Gibson-Medium.otf') format('otf');
    font-weight: normal;
    font-style: normal;
   }

   @font-face {
    font-family: "Gibson";
    src: url("../assets/fonts/Gibson-Regular.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}

`

export default GlobalStyle